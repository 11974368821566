import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { motion, AnimatePresence } from 'framer-motion';

import Hamburger from 'src/components/Hamburger';
import { useIsMobile } from 'src/utils/useIsMobile';

const links = [
  { name: 'About', path: '/' },
  { name: 'Project', path: '/project' },
  { name: 'Art Map', path: '/art-map' },
];

const menuTransition = {
  type: 'tween',
  ease: 'easeInOut',
  duration: 0.3,
};

const menuAnimation = {
  hidden: {
    x: '100%',
    transition: menuTransition,
  },
  visible: {
    x: 0,
    transition: {
      ...menuTransition,
      staggerChildren: 0.1,
      when: 'beforeChildren',
    },
  },
};

const menuItemAnimation = {
  hidden: {
    opacity: 0,
    x: -50,
  },
  visible: {
    opacity: 1,
    x: 0,
  },
};

function Navigation(props) {
  const isMobile = useIsMobile();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    document.body.style.overflow = isVisible ? 'hidden' : 'auto';
  }, [isVisible]);

  const menuItems = links.map(({ name, path }) => (
    <MainLink onClick={() => setIsVisible(false)} key={path} to={path}>
      {name}
    </MainLink>
  ));

  if (!isMobile) {
    return <Wrapper {...props}>{menuItems}</Wrapper>;
  }

  return (
    <Wrapper {...props}>
      <MenuToggle
        onClick={() => setIsVisible(!isVisible)}
        isActive={isVisible}
      />
      <AnimatePresence>
        {isVisible && (
          <Menu
            initial="hidden"
            exit="hidden"
            animate="visible"
            variants={menuAnimation}
          >
            {menuItems.map(l => (
              <MenuItem variants={menuItemAnimation}>{l}</MenuItem>
            ))}
          </Menu>
        )}
      </AnimatePresence>
    </Wrapper>
  );
}

const Menu = styled(motion.div)`
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background: #f6f3f0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const MenuItem = styled(motion.div)`
  font-size: 1.6rem;
  font-weight: normal;
  margin: 1.2rem 0;
  color: #000;
  position: relative;
`;

const MenuToggle = styled(Hamburger)`
  position: relative;
  z-index: 3;
`;

const MainLink = styled(Link)`
  text-transform: uppercase;
  color: #000;
  position: relative;
  padding: 0.5rem;
  width: 150px;

  &:after {
    content: '';
    height: 0;
    width: 30px;
    border-bottom: 0.2rem solid #000;
    position: absolute;
    bottom: 0;
    left: 0.5rem;
    transition: width 150ms ease-in-out;
  }

  &:hover:after {
    width: 60px;
  }
`;

const Wrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  font-size: 1rem;
  font-weight: 800;
`;

export default Navigation;
