import React from 'react';
import styled from '@emotion/styled';

import Navigation from './Navigation';
import Logo from './Logo';

const Wrapper = props => (
  <div {...props}>
    <StyledLogo />
    <Navigation />
  </div>
);

const StyledLogo = styled(Logo)`
  @media only screen and (max-device-width: 480px) {
    font-size: 0.6rem;
    margin: 0;
  }
`;

export default styled(Wrapper)`
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  margin: 1rem;

  @media only screen and (max-device-width: 480px) {
    margin: 0;
    margin-bottom: 2rem;
  }
`;
