import React from 'react';
import styled from '@emotion/styled';

const Logo = props => (
  <Wrapper {...props}>
    <h1>Mako Ueda</h1>
    <h2>
      <span>上田</span>
      <span>真子</span>
    </h2>
  </Wrapper>
);

const Wrapper = styled('div')`
  margin: 1em;
  display: inline-block;
  h1 {
    text-transform: uppercase;
    font-size: 1.75em;
    margin: 0;
    margin-bottom: 0.4em;
    font-weight: 800;
  }

  h2 {
    font: 400 1.5em 'Noto Sans';
    margin: 0;
    color: #888;

    span:first-of-type {
      margin-right: 3em;
    }
  }
`;

export default Logo;
