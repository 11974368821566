import React from 'react';
import { Global, css } from '@emotion/core';

import Header from './Header';

const globalCss = css`
  @font-face {
    font-family: 'Mrs Eaves OT';
    src: url('fonts/mrs-eaves-ot.ttf') format('truetype');
    font-weight: 400;
  }

  @font-face {
    font-family: 'Mrs Eaves OT';
    src: url('fonts/mrs-eaves-ot-bold.ttf') format('truetype');
    font-weight: 800;
  }

  * {
    box-sizing: border-box;
  }

  :root {
    font-size: 16px;
    font-family: 'Mrs Eaves OT';
    letter-spacing: 0.25rem;
  }

  #___gatsby {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  div[role='group'][tabindex] {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  a {
    text-decoration: none;
  }

  body {
    display: flex;
    height: 100vh;
    margin: 0;
    padding: 0.5rem;
    @media only screen and (max-device-width: 480px) {
      padding: 2rem;
    }
  }

  ::selection {
    background: rgba(0, 0, 0, 0.1);
  }
`;

const Layout = ({ children }) => (
  <React.Fragment>
    <Global styles={globalCss} />
    <Header />
    {children}
  </React.Fragment>
);

export default Layout;
