import React from 'react';
import styled from '@emotion/styled';
import css from '@emotion/css';

const getActiveStylesMiddle = p => css`
  transform: rotate(-45deg);
  transition-delay: 220ms;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
`;

const getActiveStylesTop = p => css`
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    opacity 100ms 220ms linear;
`;

const getActiveStylesBottom = p => css`
  top: 0;
  transform: rotate(-90deg);
  transition: top 100ms 160ms cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    transform 130ms 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
`;

const HamburgerInner = styled('div')`
  display: block;

  transition-duration: 130ms;
  transition-delay: 130ms;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  ${p => p.isActive && getActiveStylesMiddle(p)};

  &,
  &::before,
  &::after {
    width: 30px;
    height: 1px;
    background-color: #000;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: '';
    display: block;
  }

  &::before {
    top: -8px;
    transition: top 200ms 200ms cubic-bezier(0.33333, 0.66667, 0.66667, 1),
      opacity 100ms linear;
    ${p => p.isActive && getActiveStylesTop(p)};
  }

  &:after {
    top: 8px;
    transition: top 120ms 200ms cubic-bezier(0.33333, 0.66667, 0.66667, 1),
      transform 130ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
    ${p => p.isActive && getActiveStylesBottom(p)};
  }
`;

const HamburgerBox = styled('div')`
  width: 30px;
  height: 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Hamburger = styled(p => (
  <button {...p}>
    <HamburgerBox>
      <HamburgerInner isActive={p.isActive} />
    </HamburgerBox>
  </button>
))`
  padding: 15px;
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  outline: none;
`;

export default Hamburger;
